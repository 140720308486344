.ant-radio-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: var(--white);
	font-size: var(--h3) !important;
	font-family: var(--golos_text);
	list-style: none;
	font-feature-settings: 'tnum';
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.ant-radio-group > label {
	width: fit-content;
	margin: 0 var(--colPadding);
}
.ant-radio-group label:first-child {
	margin-left: 0;
}
.ant-radio-group .ant-badge-count {
	z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
	border-left: none;
}
.ant-radio-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: var(--white);
	font-size: var(--formSize) !important;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 8px;
	cursor: pointer;
}
.ant-radio-wrapper:not(.ant-radio-wrapper-checked) .ant-radio {
	opacity: 0.7;
}
.ant-radio-wrapper-disabled {
	cursor: not-allowed;
}
.ant-radio-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: '\a0';
}
.ant-radio {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: var(--white);
	font-size: var(--h3) !important;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	outline: none;
	cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border-color: none;
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
	visibility: visible;
}
.ant-radio-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: var(--sizeRadio);
	height: var(--sizeRadio);
	border: none;
	border-radius: 4px;
	background-color: transparent;
}
.ant-radio-inner::after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) !important;
	display: block;
	width: calc(var(--sizeRadio) / 2);
	height: calc(var(--sizeRadio) / 2);
	border: var(--borderRadio) solid white;
	border-radius: 4px;
	opacity: 0;
	content: ' ';
}
.ant-radio-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;
	border: 1px solid #ffffff;
	border-radius: 4px;
	width: var(--sizeRadio);
	height: var(--sizeRadio);
}
.ant-radio-checked .ant-radio-inner::after {
	transform: scale(0.5);
	opacity: 1;
}
.ant-radio-disabled {
	cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
	background-color: #f5f5f5;
	cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
	background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
	cursor: not-allowed;
}
.ant-radio-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
span.ant-radio + * {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-radio-button-wrapper {
	position: relative;
	display: inline-block;
	height: 32px;
	margin: 0;
	padding: 0 15px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 30px;
	background: #fff;
	border: 1px solid #d9d9d9;
	border-top-width: 1.02px;
	border-left-width: 0;
	cursor: pointer;
	transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
	color: rgba(0, 0, 0, 0.85);
}
.ant-radio-button-wrapper > .ant-radio-button {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
	height: 40px;
	font-size: 16px;
	line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
	height: 24px;
	padding: 0 7px;
	line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
	position: absolute;
	top: -1px;
	left: -1px;
	display: block;
	box-sizing: content-box;
	width: 1px;
	height: 100%;
	padding: 1px 0;
	background-color: #d9d9d9;
	transition: background-color 0.3s;
	content: '';
}
.ant-radio-button-wrapper:first-child {
	border-left: 1px solid #d9d9d9;
	border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:last-child {
	border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
	border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
	position: relative;
	color: #1890ff;
}
.ant-radio-button-wrapper:focus-within {
	box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	z-index: 1;
	color: #1890ff;
	background: #fff;
	border-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
	background-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
	border-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #40a9ff;
	border-color: #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
	background-color: #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	color: #096dd9;
	border-color: #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
	background-color: #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #fff;
	background: #1890ff;
	border-color: #1890ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #fff;
	background: #40a9ff;
	border-color: #40a9ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	color: #fff;
	background: #096dd9;
	border-color: #096dd9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-button-wrapper-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
	border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
	color: rgba(0, 0, 0, 0.25);
	background-color: #e6e6e6;
	border-color: #d9d9d9;
	box-shadow: none;
}
@keyframes antRadioEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}
.ant-radio-group.ant-radio-group-rtl {
	direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
	margin-right: 0;
	margin-left: 8px;
	direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
	border-right-width: 0;
	border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
	right: -1px;
	left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
	border-right: 1px solid #d9d9d9;
	border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
	border-right-color: #40a9ff;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
	border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
	border-right-color: #d9d9d9;
}
