@font-face {
	font-family: 'ENA-Landing';
	src: url('fonts/ENA-Landing.eot?xrrhf6');
	src: url('fonts/ENA-Landing.eot?xrrhf6#iefix') format('embedded-opentype'), url('fonts/ENA-Landing.woff2?xrrhf6') format('woff2'), url('fonts/ENA-Landing.ttf?xrrhf6') format('truetype'), url('fonts/ENA-Landing.woff?xrrhf6') format('woff'), url('fonts/ENA-Landing.svg?xrrhf6#ENA-Landing') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'ENA-Landing' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-pause:before {
	content: '\e909';
}
.icon-close:before {
	content: '\e90a';
}
.icon-warning:before {
	content: '\e900';
}
.icon-target:before {
	content: '\e901';
}
.icon-time:before {
	content: '\e902';
}
.icon-money:before {
	content: '\e903';
}
.icon-check:before {
	content: '\e904';
}
.icon-bottom-arrow:before {
	content: '\e905';
}
.icon-plus:before {
	content: '\e906';
}
.icon-minus:before {
	content: '\e907';
}
.icon-play:before {
	content: '\e908';
}
