/* cyrillic-ext */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ca73cec4783f8990.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/913784d45cedca4d.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0a4a50fce8b9a620.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f177899fe43a13a8.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ca73cec4783f8990.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/913784d45cedca4d.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0a4a50fce8b9a620.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Golos_Text_6c0444';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f177899fe43a13a8.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}.__className_6c0444 {font-family: '__Golos_Text_6c0444';font-style: normal
}

/* latin-ext */
@font-face {
  font-family: '__Zen_Dots_80af2f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/043de5c764a0a74e-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Zen_Dots_80af2f';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3b1209046a6988e1-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Zen_Dots_Fallback_80af2f';src: local("Arial");ascent-override: 66.32%;descent-override: 19.25%;line-gap-override: 0.00%;size-adjust: 140.23%
}.__className_80af2f {font-family: '__Zen_Dots_80af2f', '__Zen_Dots_Fallback_80af2f';font-weight: 400;font-style: normal
}

.ant-radio-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: var(--white);
	font-size: var(--h3) !important;
	font-family: var(--golos_text);
	list-style: none;
	font-feature-settings: 'tnum';
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.ant-radio-group > label {
	width: -moz-fit-content;
	width: fit-content;
	margin: 0 var(--colPadding);
}
.ant-radio-group label:first-child {
	margin-left: 0;
}
.ant-radio-group .ant-badge-count {
	z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
	border-left: none;
}
.ant-radio-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: var(--white);
	font-size: var(--formSize) !important;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum', "tnum";
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 8px;
	cursor: pointer;
}
.ant-radio-wrapper:not(.ant-radio-wrapper-checked) .ant-radio {
	opacity: 0.7;
}
.ant-radio-wrapper-disabled {
	cursor: not-allowed;
}
.ant-radio-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: '\a0';
}
.ant-radio {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: var(--white);
	font-size: var(--h3) !important;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: 'tnum', "tnum";
	position: relative;
	display: inline-block;
	outline: none;
	cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border-color: none;
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
	visibility: visible;
}
.ant-radio-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: var(--sizeRadio);
	height: var(--sizeRadio);
	border: none;
	border-radius: 4px;
	background-color: transparent;
}
.ant-radio-inner::after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) !important;
	display: block;
	width: calc(var(--sizeRadio) / 2);
	height: calc(var(--sizeRadio) / 2);
	border: var(--borderRadio) solid white;
	border-radius: 4px;
	opacity: 0;
	content: ' ';
}
.ant-radio-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;
	border: 1px solid #ffffff;
	border-radius: 4px;
	width: var(--sizeRadio);
	height: var(--sizeRadio);
}
.ant-radio-checked .ant-radio-inner::after {
	transform: scale(0.5);
	opacity: 1;
}
.ant-radio-disabled {
	cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
	background-color: #f5f5f5;
	cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
	background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
	cursor: not-allowed;
}
.ant-radio-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
span.ant-radio + * {
	padding-right: 8px;
	padding-left: 8px;
}
.ant-radio-button-wrapper {
	position: relative;
	display: inline-block;
	height: 32px;
	margin: 0;
	padding: 0 15px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 30px;
	background: #fff;
	border: 1px solid #d9d9d9;
	border-top-width: 1.02px;
	border-left-width: 0;
	cursor: pointer;
	transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
	color: rgba(0, 0, 0, 0.85);
}
.ant-radio-button-wrapper > .ant-radio-button {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
	height: 40px;
	font-size: 16px;
	line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
	height: 24px;
	padding: 0 7px;
	line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
	position: absolute;
	top: -1px;
	left: -1px;
	display: block;
	box-sizing: content-box;
	width: 1px;
	height: 100%;
	padding: 1px 0;
	background-color: #d9d9d9;
	transition: background-color 0.3s;
	content: '';
}
.ant-radio-button-wrapper:first-child {
	border-left: 1px solid #d9d9d9;
	border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper:last-child {
	border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
	border-radius: 2px;
}
.ant-radio-button-wrapper:hover {
	position: relative;
	color: #1890ff;
}
.ant-radio-button-wrapper:focus-within {
	box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	z-index: 1;
	color: #1890ff;
	background: #fff;
	border-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
	background-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
	border-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #40a9ff;
	border-color: #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
	background-color: #40a9ff;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	color: #096dd9;
	border-color: #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
	background-color: #096dd9;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #fff;
	background: #1890ff;
	border-color: #1890ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: #fff;
	background: #40a9ff;
	border-color: #40a9ff;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	color: #fff;
	background: #096dd9;
	border-color: #096dd9;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.12);
}
.ant-radio-button-wrapper-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
	border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
	color: rgba(0, 0, 0, 0.25);
	background-color: #e6e6e6;
	border-color: #d9d9d9;
	box-shadow: none;
}
@keyframes antRadioEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}
.ant-radio-group.ant-radio-group-rtl {
	direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
	margin-right: 0;
	margin-left: 8px;
	direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
	border-right-width: 0;
	border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
	right: -1px;
	left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
	border-right: 1px solid #d9d9d9;
	border-radius: 0 2px 2px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
	border-right-color: #40a9ff;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
	border-radius: 2px 0 0 2px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
	border-right-color: #d9d9d9;
}

@keyframes antCheckboxEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}
.ant-checkbox {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: var(--sizeRadio);
	height: var(--sizeRadio);
	color: var(--white);
	font-size: var(--h5);
	font-family: var(--golos_text);
	border-radius: 4px;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	line-height: 1;
	white-space: nowrap;
	outline: none;
	cursor: pointer;
}
.ant-checkbox:not(.ant-checkbox-checked) {
	opacity: 0.7;
}
.ant-checkbox-checked::after {
	animation-fill-mode: backwards;
	content: '';
}
.ant-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: var(--sizeRadio);
	height: var(--sizeRadio);
	direction: ltr;
	background-color: none;
	border-radius: 4px;
	border-collapse: separate;
}
.ant-checkbox-inner::after {
	content: ' ';
}
.ant-checkbox-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) !important;

	display: table;
	width: calc(var(--sizeRadio) / 2);
	height: calc(var(--sizeRadio) / 2);
	border: 7px solid var(--white);
	border-radius: 4px;
	opacity: 1;
	content: ' ';
}
.ant-checkbox-disabled {
	cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
	animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
	cursor: not-allowed;
	pointer-events: none;
}
.ant-checkbox-disabled .ant-checkbox-inner {
	border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
	border-color: #f5f5f5;
	border-collapse: separate;
	animation-name: none;
}
.ant-checkbox-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-checkbox-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum', "tnum";
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.ant-checkbox-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
	cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
	margin-left: 8px;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
	opacity: 1;
	border-radius: 4px;
	border: 1px solid var(--white);
}
.ant-checkbox + span {
	padding-right: 8px;
	padding-left: 8px;
	color: var(--white);
	font-size: var(--h5);
	font-family: var(--golos_text);
}
.ant-checkbox-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum', "tnum";
	display: inline-block;
}
.ant-checkbox-group-item {
	margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
	margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
	margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
	border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	opacity: 1;
	content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
	direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
	margin-right: 0;
	margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
	margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
	margin-left: 8px;
}

@font-face {
	font-family: 'ENA-Landing';
	src: url(/_next/static/media/ENA-Landing.4091cbed.eot);
	src: url(/_next/static/media/ENA-Landing.4091cbed.eot#iefix) format('embedded-opentype'), url(/_next/static/media/ENA-Landing.86ed84cd.woff2) format('woff2'), url(/_next/static/media/ENA-Landing.a8031804.ttf) format('truetype'), url(/_next/static/media/ENA-Landing.f38fb1b4.woff) format('woff'), url(/_next/static/media/ENA-Landing.ad84824e.svg#ENA-Landing) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'ENA-Landing' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-pause:before {
	content: '\e909';
}
.icon-close:before {
	content: '\e90a';
}
.icon-warning:before {
	content: '\e900';
}
.icon-target:before {
	content: '\e901';
}
.icon-time:before {
	content: '\e902';
}
.icon-money:before {
	content: '\e903';
}
.icon-check:before {
	content: '\e904';
}
.icon-bottom-arrow:before {
	content: '\e905';
}
.icon-plus:before {
	content: '\e906';
}
.icon-minus:before {
	content: '\e907';
}
.icon-play:before {
	content: '\e908';
}

