@keyframes antCheckboxEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}
.ant-checkbox {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	width: var(--sizeRadio);
	height: var(--sizeRadio);
	color: var(--white);
	font-size: var(--h5);
	font-family: var(--golos_text);
	border-radius: 4px;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	line-height: 1;
	white-space: nowrap;
	outline: none;
	cursor: pointer;
}
.ant-checkbox:not(.ant-checkbox-checked) {
	opacity: 0.7;
}
.ant-checkbox-checked::after {
	animation-fill-mode: backwards;
	content: '';
}
.ant-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: var(--sizeRadio);
	height: var(--sizeRadio);
	direction: ltr;
	background-color: none;
	border-radius: 4px;
	border-collapse: separate;
}
.ant-checkbox-inner::after {
	content: ' ';
}
.ant-checkbox-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) !important;

	display: table;
	width: calc(var(--sizeRadio) / 2);
	height: calc(var(--sizeRadio) / 2);
	border: 7px solid var(--white);
	border-radius: 4px;
	opacity: 1;
	content: ' ';
}
.ant-checkbox-disabled {
	cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
	animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
	cursor: not-allowed;
	pointer-events: none;
}
.ant-checkbox-disabled .ant-checkbox-inner {
	border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
	border-color: #f5f5f5;
	border-collapse: separate;
	animation-name: none;
}
.ant-checkbox-disabled + span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}
.ant-checkbox-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.ant-checkbox-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: '\a0';
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
	cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
	margin-left: 8px;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item input[type='checkbox'] {
	opacity: 1;
	border-radius: 4px;
	border: 1px solid var(--white);
}
.ant-checkbox + span {
	padding-right: 8px;
	padding-left: 8px;
	color: var(--white);
	font-size: var(--h5);
	font-family: var(--golos_text);
}
.ant-checkbox-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: 'tnum';
	display: inline-block;
}
.ant-checkbox-group-item {
	margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
	margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
	margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
	border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
	opacity: 1;
	content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
	direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
	margin-right: 0;
	margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
	margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
	margin-left: 8px;
}
